export const empNameList = [
  "E B MOULI (M20202)",
  "N V S N RAJU (M20203)",
  "BALA VENKATESWARA RAO S (M20231)",
  "K HARNADH (M20232)",
  "RAMAKRISHNA K (M91005)",
  "M.Srinivasa Rao (M93015)",
  "A SELVA KUMAR (M97038)",
  "I RAJA SEKHAR (MT0006)",
  "G HEMANTH KUMAR (MT5002)",
  "M PRAVEEN BABU (MT6012)",
  "B MAHENDRA RAJU (MT6021)",
  "PRABAKAR M (MT6037)",
  "Y V VIJAYA KUMAR (MT6047)",
  "G ARUN KUMAR (MT6049)",
  "P JAY KUMAR (MT6060)",
  "J VIJAYA KUMAR (MT6061)",
  "A M V PRASAD (MT6063)",
  "M SRINIVAS (MT6147)",
  "HARISH KUMAR K (MT6171)",
  "GANESAN S (MT6176)",
  "NAZEER SHEIK (MT6181)",
  "V MADHU BABU (MT6183)",
  "CHINTAMANI RAUL (MT6184)",
  "ESWAR RAO K (MT6189)",
  "JAGDISH KUMAR CHAGANTI (MT6190)",
  "SANAT KUMAR SAHU (MT6191)",
  "K SRI KRISHNA (MT6210)",
  "ANAND A (MT6237)",
  "PINNAMASETTI S V D PAVAN (MT6239)",
  "VULLENDULA LINGAIAH (MT6241)",
  "NEKKALAPU KIRAN KUMAR (MT6243)",
  "BELLAMKONDA U G S MANI KRISHNA (MT6258)",
  "POLISETTY VENKATA PAVAN KUMAR (MT6261)",
  "VOOTAKUTI NOOKARAJ KUMAR (MT6267)",
  "BIJOMON K X (MT6268)",
  "NUTI V NAGESWARARAO (MT6273)",
  "VARASALA MANOJ (MT6274)",
  "ANUMALA THIRUPATHI (MT6283)",
  "MELVIN BOSE BOAZ (MT6284)",
  "KRISHNARAJ (MT6285)",
  "G. Radhakrishna Murthy (MT6286)",
  "KALAKATA SIVAKUMAR (MT6287)",
  "AUSTIN NETHAJI S (MT6288)",
  "NAMTABAD HUMESH (MT6289)",
  "JITHIN JOHNSON (MT6291)",
  "GANDHIRAJAN SIVAKUMAR (MT6293)",
  "S.NARAYANAN (MT6294)",
  "KANDULA CHINNA ANJI REDDY (MT6295)",
  "SIVAKUMAR THYAGARAJAN (MT6297)",
  "BALIVADA SAI RAM SOWRYA (MT6298)",
  "THOTA NAGA SRINIVASA RAO (MT6303)",
  "DIYYA VENKATAIAH (MT6304)",
  "BUDATI SRI SIVA NAGENDRA KUMAR (MT6305)",
  "NANTHAKUMAR SUDALAIMUTHU (MT6307)",
  "SARANKUMAR GOPAL (MT6308)",
  "DONE RAJESH BABU (MT6310)",
  "SENTHILKUMAR MARIMUTHU (MT6311)",
  "NANDAVARAPU ANJANEYULU (MT6312)",
  "P PAUL ARPUTHARAJ (MT6313)",
  "KURANGI NAGESHWARARAO (MT6317)",
  "AZEEM HAFEEZ M (MT6318)",
  "M G RAM PRABHU (MT6319)",
  "K PARASHU RAMUDU (MT6320)",
  "UDI KUMAR (MT6325)",
  "PENDYALA RAVIKUMAR (MT6326)",
  "MANIVEL KRISHNAN (MT6328)",
  "P NAVEEN KUMAR (MT6330)",
  "KALLA VENKATA NAGESWARA RAO (MT6332)",
  "VISHNUKUMAR PANDIAN (MT6333)",
  "K S T S NAGANARAYANA (MT6334)",
  "SRIKANTH THATI (MT6336)",
  "GANGURI NARESH (MT6338)",
  "KRISHNAMOORTHY KANDASAMY (MT6341)",
  "SEETHABATHI SIVAJI (MT6342)",
  "VEDANTAM RANGANATH (MT6343)",
  "EEPURI SIVA (MT6344)",
  "KORNU RAJASEKAR (MT6345)",
  "KOTHA SHIVA KUMAR (MT6346)",
];

// [
//     M20202   E B MOULI (M20202)
// M20203   N V S N RAJU (M20203)
// M20231   BALA VENKATESWARA RAO S (M20231)
// M20232   K HARNADH (M20232)
// M91005   RAMAKRISHNA K (M91005)
// M93015   M.Srinivasa Rao (M93015)
// M97038   A SELVA KUMAR (M97038)
// MT0006   I RAJA SEKHAR (MT0006)
// MT5002   G HEMANTH KUMAR (MT5002)
// MT6012   M PRAVEEN BABU (MT6012)
// MT6021   B MAHENDRA RAJU (MT6021)
// MT6037   PRABAKAR M (MT6037)
// MT6047   Y V VIJAYA KUMAR (MT6047)
// MT6049   G ARUN KUMAR (MT6049)
// MT6060   P JAY KUMAR (MT6060)
// MT6061   J VIJAYA KUMAR (MT6061)
// MT6063   A M V PRASAD (MT6063)
// MT6147   M SRINIVAS (MT6147)
// MT6171   HARISH KUMAR K (MT6171)
// MT6176   GANESAN S (MT6176)
// MT6181   NAZEER SHEIK (MT6181)
// MT6183   V MADHU BABU (MT6183)
// MT6184   CHINTAMANI RAUL (MT6184)
// MT6189   ESWAR RAO K (MT6189)
// MT6190   JAGDISH KUMAR CHAGANTI (MT6190)
// MT6191   SANAT KUMAR SAHU (MT6191)
// MT6210   K SRI KRISHNA (MT6210)
// MT6237   ANAND A (MT6237)
// MT6239   PINNAMASETTI S V D PAVAN (MT6239)
// MT6241   VULLENDULA LINGAIAH (MT6241)
// MT6243   NEKKALAPU KIRAN KUMAR (MT6243)
// MT6258   BELLAMKONDA U G S MANI KRISHNA (MT6258)
// MT6261   POLISETTY VENKATA PAVAN KUMAR (MT6261)
// MT6267   VOOTAKUTI NOOKARAJ KUMAR (MT6267)
// MT6268   BIJOMON K X (MT6268)
// MT6273   NUTI V NAGESWARARAO (MT6273)
// MT6274   VARASALA MANOJ (MT6274)
// MT6283   ANUMALA THIRUPATHI (MT6283)
// MT6284   MELVIN BOSE BOAZ (MT6284)
// MT6285   KRISHNARAJ (MT6285)
// MT6286   G. Radhakrishna Murthy (MT6286)
// MT6287   KALAKATA SIVAKUMAR (MT6287)
// MT6288   AUSTIN NETHAJI S (MT6288)
// MT6289   NAMTABAD HUMESH (MT6289)
// MT6291   JITHIN JOHNSON (MT6291)
// MT6293   GANDHIRAJAN SIVAKUMAR (MT6293)
// MT6294   S.NARAYANAN (MT6294)
// MT6295   KANDULA CHINNA ANJI REDDY (MT6295)
// MT6297   SIVAKUMAR THYAGARAJAN (MT6297)
// MT6298   BALIVADA SAI RAM SOWRYA (MT6298)
// MT6303   THOTA NAGA SRINIVASA RAO (MT6303)
// MT6304   DIYYA VENKATAIAH (MT6304)
// MT6305   BUDATI SRI SIVA NAGENDRA KUMAR (MT6305)
// MT6307   NANTHAKUMAR SUDALAIMUTHU (MT6307)
// MT6308   SARANKUMAR GOPAL (MT6308)
// MT6310   DONE RAJESH BABU (MT6310)
// MT6311   SENTHILKUMAR MARIMUTHU (MT6311)
// MT6312   NANDAVARAPU ANJANEYULU (MT6312)
// MT6313   P PAUL ARPUTHARAJ (MT6313)
// MT6317   KURANGI NAGESHWARARAO (MT6317)
// MT6318   AZEEM HAFEEZ M (MT6318)
// MT6319   M G RAM PRABHU (MT6319)
// MT6320   K PARASHU RAMUDU (MT6320)
// MT6325   UDI KUMAR (MT6325)
// MT6326   PENDYALA RAVIKUMAR (MT6326)
// MT6328   MANIVEL KRISHNAN (MT6328)
// MT6330   P NAVEEN KUMAR (MT6330)
// MT6332   KALLA VENKATA NAGESWARA RAO (MT6332)
// MT6333   VISHNUKUMAR PANDIAN (MT6333)
// MT6334   K S T S NAGANARAYANA (MT6334)
// MT6336   SRIKANTH THATI (MT6336)
// MT6338   GANGURI NARESH (MT6338)
// MT6341   KRISHNAMOORTHY KANDASAMY (MT6341)
// MT6342   SEETHABATHI SIVAJI (MT6342)
// MT6343   VEDANTAM RANGANATH (MT6343)
// MT6344   EEPURI SIVA (MT6344)
// MT6345   KORNU RAJASEKAR (MT6345)
// MT6346   KOTHA SHIVA KUMAR (MT6346)

// ]
