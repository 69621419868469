import { useContext, useEffect } from "react";
import { appContext } from "../../../App";
import { leaveType } from "../../../dataset/attendance";
import "../IndexPage/AttendanceStyle.css";

export const AttendanceStatus = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  useEffect(() => {
    changeSubMenu(currentSubMenu);
  });

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  return (
    <div className="sh_content-container">
      <div className="sh_content-header">
        <div className="sh_content_header_content">
          <span>Attendance Type</span>
        </div>
        <div className="sh_shift_container">
          {leaveType.map((leaveItem, index) => {
            return (
              <div className="leave-type-card">
                <span
                  className="leave-short-name"
                  style={{ color: leaveItem.color }}
                >
                  {leaveItem.ShortName}
                </span>
                <span className="leave-type">{leaveItem.Type}</span>
                <i className="fa fa-circle-exclamation" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="sh_content_view">
        {/* <div className="ph_content_header"> 
          <span>Attendance Status</span>
          <div className="">
            <input type="search" />
            <input type="date" />
            <input type="date" />
          </div>
        </div>*/}
      </div>
    </div>
  );
};
