import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { appContext } from "../../../App";
import "../IndexPage/OraganisationStyle.css";
import { TableGrid } from "../../../components/TableComponent/TableGrid";
import {
  organisationAdmins,
  organisationRoles,
} from "../../../dataset/organisation";

export const Admins = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  const [editIndex, setEditIndex] = useState(-1);

  const [showNewDataPage, setShowNewDataPage] = useState(false);

  const [adminData, setAdminData] = useState(organisationAdmins.rows);

  const [total, setTotal] = useState(adminData.length);

  const [searchAdmin, setSearchAdmin] = useState({});

  const ref_orgData_name = useRef();
  const ref_orgData_mail = useRef();
  const ref_orgData_role = useRef();
  const ref_orgData_reporting = useRef();
  const ref_search_admin = useRef();
  //const ref_orgData_mobNo = useRef();

  useEffect(() => {
    changeSubMenu(currentSubMenu);
    setTotal(adminData.length);
  }, [adminData, currentSubMenu, changeSubMenu]);

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  const searchAdminFunc = useCallback(() => {
    setSearchAdmin(
      adminData.filter((item, index) => {
        let searchRegex = new RegExp(ref_search_admin.current.value, "gi");
        return item.empName.match(searchRegex);
      })
    );
  }, [adminData]);

  useEffect(() => {
    searchAdminFunc();
  }, [adminData, searchAdminFunc]);

  const sliceEmpName = (name) => {
    let newl = 0;
    for (let a = 0; a < name.length; a++) {
      if (name[a] === " ") {
        newl = a + 1;
        break;
      }
    }
    return newl > 0 ? name[0] + name[newl] : name[0] + name[1];
  };

  const columns = [
    {
      // field: ["empName", "mail"],
      field: "empName",
      headerName: "Employee",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="org_component">
          <div className="org-name-icon">
            <span>
              {editIndex >= 0 || sliceEmpName(cellValues.row.empName)}
            </span>
          </div>
          <div className="ord-emp-det">
            <span className="emp-name">{cellValues.row.empName}</span>
            <span className="emp-mail">{cellValues.row.mail}</span>
          </div>
        </div>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div
          className="org-admin-role"
          style={{
            backgroundColor:
              cellValues.row.role === "Company Admin"
                ? "rgb(205, 245, 205)"
                : "#CCE5FF",
            color:
              cellValues.row.role === "Company Admin" ? "green" : "dodgerblue",
          }}
        >
          <span>{cellValues.row.role}</span>
        </div>
      ),
    },
    {
      field: "empVisibility",
      headerName: "Executive Visibility",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "reportingEmp",
      headerName: "Reports to",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "fieldExecutives",
      headerName: "Field Executives",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      sortable: false,
      headerClassName: "grid-header",
      flex: 0.25,
      // headerClassName: 'super-app-theme--header',
      // headerAlign: 'center',
      renderCell: (cellValues) => (
        <div className="action-cell">
          <i
            className="fa fa-edit"
            onClick={() => {
              setShowNewDataPage(true);
              setEditIndex(cellValues.row.id);
              // console.log(cellValues.api.getRowIndex(cellValues.row.id));
            }}
          />
          <i
            className="fa fa-trash trash-icon"
            onClick={(event) => deleteOrgData(cellValues.row.id)}
          />
        </div>
      ),
    },
  ];

  const saveOrgData = () => {
    if (editIndex === -1) {
      setAdminData([
        ...adminData,
        {
          id: adminData.length + 1,
          empName: ref_orgData_name.current.value,
          role: ref_orgData_role.current.value,
          mail: ref_orgData_mail.current.value,
          empVisibility: ref_orgData_name.current.value,
          reportingEmp: ref_orgData_reporting.current.value,
          fieldExecutives: 1,
        },
      ]);
    } else {
      let newRolesData = [];
      adminData.map((item, index) => {
        return (newRolesData = [
          ...newRolesData,
          {
            id: item.id,
            empName:
              item.id === editIndex
                ? ref_orgData_name.current.value
                : item.empName,
            role:
              item.id === editIndex
                ? ref_orgData_role.current.value
                : item.role,
            mail:
              item.id === editIndex
                ? ref_orgData_mail.current.value
                : item.mail,
            empVisibility:
              item.id === editIndex
                ? ref_orgData_name.current.value
                : item.empName,
            reportingEmp:
              item.id === editIndex
                ? ref_orgData_reporting.current.value
                : item.reportingEmp,
            fieldExecutives: 1,
          },
        ]);
      });
      setAdminData(newRolesData);
      setEditIndex(-1);
    }
    setShowNewDataPage(!showNewDataPage);
  };

  const deleteOrgData = (num) => {
    let newTeamData = adminData.filter((item, index) => {
      return item.id !== num;
    });
    setAdminData(newTeamData);
  };

  const NewOrgData = () => {
    return (
      <div className="org-new-emp-page">
        <form
          action=""
          className="org-new-emp-form"
          onSubmit={() => {
            saveOrgData();
          }}
        >
          <label htmlFor="">
            {editIndex >= 0 ? "Edit Admin :" : "Add Admin :"}
          </label>
          <i
            className="fa fa-close"
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
              setEditIndex(-1);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Admin Name"
            ref={ref_orgData_name}
            required
            defaultValue={
              editIndex >= 0 ? adminData[editIndex - 1]["empName"] : ""
            }
            onInput={(e) => {
              var input = e.target;
              var start = input.selectionStart;
              var end = input.selectionEnd;
              input.value = input.value.toLocaleUpperCase();
              input.setSelectionRange(start, end);
            }}
          />
          <br />
          <input
            type="email"
            placeholder="Admin Mail"
            ref={ref_orgData_mail}
            required
            defaultValue={
              editIndex >= 0 ? adminData[editIndex - 1]["mail"] : ""
            }
            // onInput={(e) => {
            //   var input = e.target;
            //   var start = input.selectionStart;
            //   var end = input.selectionEnd;
            //   input.value = input.value.toLocaleUpperCase();
            //   input.setSelectionRange(start, end);
            // }}
          />
          <br />
          {/* <input
            type="number"
            placeholder="Admin Mobile"
            ref={ref_orgData_mobNo}
            required
            // maxLength={10}
            // defaultValue={
            //   editIndex >= 0 ? rolesData[editIndex - 1]["roleName"] : ""
            // }
          />
          <br /> */}
          <select
            ref={ref_orgData_role}
            defaultValue={
              editIndex >= 0 ? adminData[editIndex - 1]["role"] : ""
            }
          >
            {organisationRoles.rows.map((item, index) => {
              return (
                <option key={index} value={item.roleName}>
                  {item.roleName}
                </option>
              );
            })}
          </select>

          <select
            ref={ref_orgData_reporting}
            defaultValue={
              editIndex >= 0 ? adminData[editIndex - 1]["reportingEmp"] : ""
            }
          >
            {organisationAdmins.rows.map((item, index) => {
              return (
                <option key={index} value={item.empName}>
                  {item.empName}
                </option>
              );
            })}
          </select>
          <br />
          <input type="submit" value="Save" className="submit-btn" />
        </form>
      </div>
    );
  };

  return (
    <div className="org_content-container">
      {showNewDataPage && <NewOrgData />}
      <div className="org-header">
        <span>Admins {total > 0 ? "(" + total + ")" : ""}</span>
        <div className="org-inp-container">
          <input
            type="search"
            placeholder="Search Here"
            ref={ref_search_admin}
            onChange={searchAdminFunc}
          />
          <div className="emp-hirarchy">
            <div className="left">
              <span>Org Hierarchy</span>
            </div>
            <div className="right">
              <span>List</span>
            </div>
          </div>
          <button
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
            }}
          >
            <i className="fa fa-plus" />
            Add
          </button>
        </div>
      </div>
      <div className="org-table-container">
        <TableGrid
          tableRow={searchAdmin}
          tableColumn={columns}
          rowHeight={70}
          initialPageSize={20}
        />
      </div>
    </div>
  );
};
