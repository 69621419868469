import { useContext, useEffect, useRef, useState } from "react";
import { TableGrid } from "../../../components/TableComponent/TableGrid";
import { appContext } from "../../../App";
import "../IndexPage/AttendanceStyle.css";
import logo from "../../../images/ph-no-data.png";

export const PublicHolidays = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  const [showNewHolidayPage, setshowNewHolidayPage] = useState(false);

  const [holidays, setHolidays] = useState([]);

  const ref_holidayName = useRef();
  const ref_holidayDate = useRef();

  useEffect(() => {
    changeSubMenu(currentSubMenu);
    if (showNewHolidayPage) ref_holidayName.current.focus();
  });

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  const columns = [
    {
      field: "holidayName",
      headerName: "Holiday",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "holidayDate",
      headerName: "Holiday Date",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      headerClassName: "grid-header",
      sortable: false,
      flex: 0,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <i
            className="fa fa-trash trash-icon"
            onClick={(event) => deleteOrgData(cellValues.row.id)}
          />
        </div>
      ),
    },
  ];

  const saveOrgData = () => {
    setHolidays([
      ...holidays,
      {
        id: holidays.length + 1,
        holidayName: ref_holidayName.current.value,
        holidayDate: ref_holidayDate.current.value,
      },
    ]);
    setshowNewHolidayPage(!showNewHolidayPage);
  };

  const deleteOrgData = (num) => {
    let newTeamData = holidays.filter((item, index) => {
      return item.id !== num;
    });
    setHolidays(newTeamData);
  };

  const NewHolidayPage = () => {
    return (
      <div className="new-shift-page1">
        <form
          action=""
          className="new-shift-form1"
          onSubmit={() => {
            saveOrgData();
          }}
        >
          <label htmlFor="">Holiday Name : </label>
          <i
            className="fa fa-close"
            onClick={() => {
              setshowNewHolidayPage(false);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Holiday Name"
            ref={ref_holidayName}
            required
          />
          <br />
          <label htmlFor="">Holiday Date : </label>
          <br />
          <input
            type="date"
            placeholder="Holiday Date"
            ref={ref_holidayDate}
            required
            defaultValue={new Date().toISOString().substr(0, 10)}
          />
          <br />
          <div
            style={{
              margin: "auto",
            }}
          >
            <input type="submit" value="Save" className="submit-btn" />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="ph_content-container">
      {showNewHolidayPage && <NewHolidayPage />}
      <div className="ph_content-header">
        <span>Public Holidays</span>
        <div className="ph_header_input">
          <input type="search" placeholder="Search Here" />
          <input type="date" />
          <button
            onClick={() => {
              setshowNewHolidayPage(true);
            }}
          >
            <i className="fa fa-plus" /> Add
          </button>
        </div>
      </div>

      {holidays >= 0 ? (
        <div className="ph_content_container">
          <div className="ph_content_view">
            <img src={logo} alt="ph" />
            <span>No Records Available</span>
            <button
              onClick={() => {
                setshowNewHolidayPage(true);
              }}
            >
              {" "}
              <i className="fa fa-plus" />
              Add New Public Holiday
            </button>
          </div>
        </div>
      ) : (
        <div className="ph_table_container">
          <TableGrid
            tableRow={holidays}
            tableColumn={columns}
            rowHeight={50}
            initialPageSize={10}
          />
        </div>
      )}
    </div>
  );
};
