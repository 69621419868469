import "./app.css";
import Menus from "./dataset/menu";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Base } from "./pages/Base";
import { Home } from "./pages/Dashboard/IndexPage/Home";
import { Attendance } from "./pages/Attendance/IndexPage/Attendance";
import { Organisation } from "./pages/Organisation/IndexPage/Oraganisation";
import { Tasks } from "./pages/Tasks/IndexPage/Tasks";
import { Form } from "./pages/Form/IndexPage/Form";
import { Order } from "./pages/Order/IndexPage/Order";
import { Expenses } from "./pages/Expenses/IndexPage/Expenses";
import { ClientsAndSites } from "./pages/ClientsAndSites/IndexPage/ClientsAndSites";
import { Reports } from "./pages/Reports/IndexPage/Reports";
import { SubPage } from "./components/SubPage/SubPage";
import { TimeLine } from "./pages/Dashboard/SubMenuPage/TimeLine";
import { PublicHolidays } from "./pages/Attendance/SubMenuPage/PublicHolidays";
import { Shifts } from "./pages/Attendance/SubMenuPage/Shifts";
import { Roaster } from "./pages/Attendance/SubMenuPage/Roaster";
import { AttendanceStatus } from "./pages/Attendance/SubMenuPage/AttendanceStatus";
import { Roles } from "./pages/Organisation/SubMenuPage/Roles";
import { Designation } from "./pages/Organisation/SubMenuPage/Designation";
import { Teams } from "./pages/Organisation/SubMenuPage/Teams";
import { Admins } from "./pages/Organisation/SubMenuPage/Admins";
import { FieldExecutives } from "./pages/Organisation/SubMenuPage/FieldExecutives";

// import { Roaster } from "./pages/Attendance/Roaster";

import { createContext } from "react";

export const appContext = createContext();

function App() {
  const [currentMenuPage, setCurrentMenuPage] = useState(0);
  const [currentSubMenu, setCurrentSubMenu] = useState(0);

  return (
    <div className="App">
      <appContext.Provider
        value={{
          pageMenu: Menus,
          currentMenuPage: currentMenuPage,
          changeMenuPage: setCurrentMenuPage,
          currentSubMenu: currentSubMenu,
          changeSubMenu: setCurrentSubMenu,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Base />}>
              <Route path="/" element={<Home />}>
                <Route
                  index
                  element={
                    <SubPage currentSubMenu={0} content="Overview Page" />
                  }
                />
                <Route
                  path="dashboard/live_location"
                  element={
                    <SubPage currentSubMenu={1} content="Live Location Page" />
                  }
                />
                <Route
                  path="dashboard/timeline"
                  element={
                    <TimeLine currentSubMenu={2} content="TimeLine Page" />
                  }
                />
                <Route
                  path="dashboard/card_view"
                  element={
                    <SubPage currentSubMenu={3} content="Card View Page" />
                  }
                />
                <Route
                  path="dashboard/compliance_status"
                  element={
                    <SubPage
                      currentSubMenu={4}
                      content="Compliance Status Page"
                    />
                  }
                />
                <Route
                  path="dashboard/site_attendance"
                  element={
                    <SubPage
                      currentSubMenu={5}
                      content="Site Attendance Page"
                    />
                  }
                />
              </Route>

              <Route path="/attendance" element={<Attendance />}>
                <Route
                  index
                  element={
                    <SubPage currentSubMenu={0} content="Overview Page" />
                  }
                />
                <Route
                  path="/attendance/attendance_status"
                  element={
                    <AttendanceStatus
                      currentSubMenu={1}
                      content="Attendance Status Page"
                    />
                  }
                />
                <Route
                  path="/attendance/shifts"
                  element={<Shifts currentSubMenu={2} content="Shifts Page" />}
                />
                <Route
                  path="/attendance/roaster"
                  element={
                    <Roaster currentSubMenu={3} content="Roaster Page" />
                  }
                />
                <Route
                  path="/attendance/public_holidays"
                  element={
                    <PublicHolidays currentSubMenu={4} content="Roaster Page" />
                  }
                />
                <Route
                  path="/attendance/leaves"
                  element={<SubPage currentSubMenu={5} content="Leaves Page" />}
                />
                <Route
                  path="/attendance/override_request"
                  element={
                    <SubPage
                      currentSubMenu={6}
                      content="Override Request Page"
                    />
                  }
                />
              </Route>

              <Route path="organisation" element={<Organisation />}>
                <Route
                  path="/organisation/field_executive"
                  element={
                    <FieldExecutives
                      currentSubMenu={0}
                      content="Field Executive Page"
                    />
                  }
                />
                <Route
                  path="/organisation/admins"
                  element={<Admins currentSubMenu={1} content="Admin Page" />}
                />
                <Route
                  path="/organisation/roles"
                  element={<Roles currentSubMenu={2} content="Roles Page" />}
                />
                <Route
                  path="/organisation/designation"
                  element={
                    <Designation
                      currentSubMenu={3}
                      content="Designation Page"
                    />
                  }
                />
                <Route
                  path="/organisation/teams"
                  element={<Teams currentSubMenu={4} content="Teams Page" />}
                />
              </Route>

              <Route path="tasks" element={<Tasks />}>
                {Menus[3]["SubMenus"].map((submenu, index) => {
                  return (
                    <>
                      <Route
                        key={index}
                        path={submenu.SubMenuLink}
                        element={
                          <SubPage
                            key={index}
                            currentSubMenu={index}
                            content={submenu.SubMenuName + " Page"}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Route>
              <Route path="form" element={<Form />}>
                {Menus[4]["SubMenus"].map((submenu, index) => {
                  return (
                    <>
                      <Route
                        key={index}
                        path={submenu.SubMenuLink}
                        element={
                          <SubPage
                            key={index}
                            currentSubMenu={index}
                            content={submenu.SubMenuName + " Page"}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Route>

              <Route path="order" element={<Order />}>
                {Menus[5]["SubMenus"].map((submenu, index) => {
                  return (
                    <>
                      <Route
                        key={index}
                        path={submenu.SubMenuLink}
                        element={
                          <SubPage
                            key={index}
                            currentSubMenu={index}
                            content={submenu.SubMenuName + " Page"}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Route>

              <Route path="expense" element={<Expenses />}>
                {Menus[6]["SubMenus"].map((submenu, index) => {
                  return (
                    <>
                      <Route
                        key={index}
                        path={submenu.SubMenuLink}
                        element={
                          <SubPage
                            key={index}
                            currentSubMenu={index}
                            content={submenu.SubMenuName + " Page"}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Route>

              <Route path="clients_sites" element={<ClientsAndSites />}>
                {Menus[7]["SubMenus"].map((submenu, index) => {
                  return (
                    <>
                      <Route
                        key={index}
                        path={submenu.SubMenuLink}
                        element={
                          <SubPage
                            key={index}
                            currentSubMenu={index}
                            content={submenu.SubMenuName + " Page"}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Route>

              <Route path="reports" element={<Reports />}>
                {Menus[8]["SubMenus"].map((submenu8, index) => {
                  return (
                    <>
                      <Route
                        key={index}
                        path={submenu8.SubMenuLink}
                        element={
                          <SubPage
                            key={index}
                            currentSubMenu={index}
                            content={submenu8.SubMenuName + " Page"}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </appContext.Provider>
    </div>
  );
}

export default App;
