import { useContext } from "react";
import { appContext } from "../../../App";
import { Outlet, Link } from "react-router-dom";
import { useEffect } from "react";

export const Organisation = ({ Menus }) => {
  const { pageMenu, changeMenuPage, currentSubMenu, changeSubMenu } =
    useContext(appContext);

  useEffect(() => {
    changeMenuPage(2);
  });

  return (
    <>
      <div className="content-menu-group">
        {pageMenu[2] &&
          pageMenu[2]["SubMenus"].map((item, index) => {
            return (
              <Link
                to={item.SubMenuLink}
                onClick={() => {
                  changeSubMenu(index);
                }}
                className={`content-menu-btn ${
                  currentSubMenu === index ? "content-menu-btn-active" : ""
                }`}
                key={index}
              >
                <i className={`fa ${item.SunMenuIcon} sub-menu-icon`}></i>
                <span>{item.SubMenuName}</span>
              </Link>
            );
          })}
      </div>

      <Outlet />

      {/* <SubPage content="Sub Menu Page" /> */}
    </>
  );
  // return (
  //   <>
  //       <h2 style={{ marginTop: 15}}>Organisation Page</h2>
  //   </>
  // );
};
