import { Outlet, Link } from "react-router-dom";
import { useContext } from "react";
import { appContext } from "../../../App";
import { useEffect } from "react";
// import { Roaster } from "./Roaster";

export const Attendance = () => {
  const { pageMenu, changeMenuPage, currentSubMenu, changeSubMenu } =
    useContext(appContext);

  useEffect(() => {
    changeMenuPage(1);
  });

  return (
    <>
      <div className="content-menu-group">
        {pageMenu[1] &&
          pageMenu[1]["SubMenus"].map((item, index) => {
            return (
              <Link
                to={item.SubMenuLink}
                onClick={() => {
                  changeSubMenu(index);
                }}
                className={`content-menu-btn ${
                  currentSubMenu === index ? "content-menu-btn-active" : ""
                }`}
              >
                <i class={`fa ${item.SunMenuIcon} sub-menu-icon`}></i>
                <span>{item.SubMenuName}</span>
              </Link>
            );
          })}
      </div>

      {/* <Route>
          {pageMenu[1]["SubMenus"].map((submenu, index) => {
            return (
              <>
                <Route
                  path={submenu.SubMenuLink}
                  element={<Roaster content={submenu.SubMenuName + " Page"} />}
                />
              </>
            );
          })}
        </Route>
      </Routes> */}

      <Outlet />

      {/* <SubPage content="Sub Menu Page" /> */}
    </>
  );
};
