// import { useEffect, useState } from "react";
import "./mainContent.css";
import { Outlet } from "react-router-dom";

export const MainContent = () => {
  // const [btnClick, setBtnClick] = useState(0);

  // const handleMenuBtnClick = (index) => {
  //   setBtnClick(index);
  // };

  // useEffect(() => {
  //   setBtnClick(0);
  // }, [currentPage]);

  // useEffect(() => {
  //   document.title =
  //     pageMenu[currentPage]["SubMenus"][btnClick]["SubMenuName"] +
  //     " | " +
  //     pageMenu[currentPage]["MenuName"];
  // }, [pageMenu, currentPage, btnClick]);

  return (
    <div className="main-content">
      <div className="content-icons-group">
        <span className="content-icon">
          <i className="fa fa-message"></i>
        </span>
        <span className="content-icon">
          <i className="fa fa-bell"></i>
        </span>
        <span className="content-icon">
          <i className="fa fa-chevron-down"></i>
        </span>
      </div>

      {/* <div className="content-menu-group">
        {pageMenu[currentPage] &&
          pageMenu[currentPage]["SubMenus"].map((item, index) => {
            return (
              <Link
                to={item.SubMenuLink}
                onClick={() => {
                  handleMenuBtnClick(index);
                }}
                className={`content-menu-btn ${
                  btnClick === index ? "content-menu-btn-active" : ""
                }`}
              >
                <i className={`fa ${item.SunMenuIcon} sub-menu-icon`}></i>
                <span>{item.SubMenuName}</span>
              </Link>
            );
          })}
     </div> */}
      {/* {pageMenu[currentPage] &&
          pageMenu[currentPage]["SubMenus"].map((item, index) => {
            return (
              <p
                onClick={() => {
                  handleMenuBtnClick(index);
                }}
                className={`content-menu-btn ${
                  btnClick === index ? "content-menu-btn-active" : ""
                }`}
              >
                {item}
              </p>
            );
          })} */}

      <div className="child-container">
        <Outlet />
      </div>
    </div>
  );
};
