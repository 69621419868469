import { useContext, useEffect } from "react";
import { appContext } from "../../App";

export const SubPage = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  useEffect(() => {
    changeSubMenu(currentSubMenu);
  });

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  return (
    <>
      <div
        className="org_content-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* <h1 style={{ marginTop: 35, textAlign:"center",fontSize:32}}>{content}</h1> */}
        <h2
          style={{
            marginTop: 35,
            textAlign: "center",
            fontSize: 52,
            color: "dodgerblue",
          }}
        >
          Under Development
        </h2>
      </div>
    </>
  );
};
