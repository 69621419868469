let Menus = [
  {
    MenuName: "Dashboard",
    MenuLink: "/",
    MenuIcon: "fa-house",
    SubMenus: [
      {
        SubMenuName: "Overview",
        SubMenuLink: "/",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Live Location",
        SubMenuLink: "/dashboard/live_location",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Timeline",
        SubMenuLink: "/dashboard/timeline",
        SunMenuIcon: "fa-location",
      },
      {
        SubMenuName: "Card View",
        SubMenuLink: "/dashboard/card_view",
        SunMenuIcon: "fa-bars",
      },
      {
        SubMenuName: "Compliance Status",
        SubMenuLink: "/dashboard/compliance_status",
        SunMenuIcon: "fa-circle-exclamation",
      },
      {
        SubMenuName: "Site Attendance",
        SubMenuLink: "/dashboard/site_attendance",
        SunMenuIcon: "fa-location-dot",
      },
    ],
  },

  {
    MenuName: "Attendance",
    MenuLink: "/attendance",
    MenuIcon: "fa-calendar-days",
    SubMenus: [
      {
        SubMenuName: "Overview",
        SubMenuLink: "/attendance",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Attendance Status",
        SubMenuLink: "/attendance/attendance_status",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Shifts",
        SubMenuLink: "/attendance/shifts",
        SunMenuIcon: "fa-location",
      },
      {
        SubMenuName: "Roaster",
        SubMenuLink: "/attendance/roaster",
        SunMenuIcon: "fa-bars",
      },
      {
        SubMenuName: "Public Holidays",
        SubMenuLink: "/attendance/public_holidays",
        SunMenuIcon: "fa-circle-exclamation",
      },
      {
        SubMenuName: "Leaves",
        SubMenuLink: "/attendance/leaves",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Override Request",
        SubMenuLink: "/attendance/override_request",
        SunMenuIcon: "fa-bars",
      },
    ],
  },

  {
    MenuName: "Organisation",
    MenuLink: "/organisation/field_executive",
    MenuIcon: "fa-user",
    SubMenus: [
      {
        SubMenuName: "Field Executives",
        SubMenuLink: "/organisation/field_executive",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Admins",
        SubMenuLink: "/organisation/admins",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Roles",
        SubMenuLink: "/organisation/roles",
        SunMenuIcon: "fa-location",
      },
      {
        SubMenuName: "Designation",
        SubMenuLink: "/organisation/designation",
        SunMenuIcon: "fa-bars",
      },
      {
        SubMenuName: "Teams",
        SubMenuLink: "/organisation/teams",
        SunMenuIcon: "fa-circle-exclamation",
      },
    ],
  },

  {
    MenuName: "Tasks",
    MenuLink: "/tasks/overview",
    MenuIcon: "fa-list-check",
    SubMenus: [
      {
        SubMenuName: "Overview",
        SubMenuLink: "/tasks/overview",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Assign",
        SubMenuLink: "/tasks/assign",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Routes",
        SubMenuLink: "/tasks/routes",
        SunMenuIcon: "fa-location",
      },
      {
        SubMenuName: "PJP Requests",
        SubMenuLink: "/tasks/pjp_requests",
        SunMenuIcon: "fa-bars",
      },
      {
        SubMenuName: "Tasks",
        SubMenuLink: "/tasks/tasks",
        SunMenuIcon: "fa-circle-exclamation",
      },
    ],
  },

  {
    MenuName: "Form",
    MenuLink: "/form",
    MenuIcon: "fa-file-word",
    SubMenus: [
      {
        SubMenuName: "Forms",
        SubMenuLink: "/form",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Forms Responses",
        SubMenuLink: "/form/responses",
        SunMenuIcon: "fa-location-dot",
      },
    ],
  },

  {
    MenuName: "Order",
    MenuLink: "/order",
    MenuIcon: "fa-truck",
    SubMenus: [
      {
        SubMenuName: "Overview",
        SubMenuLink: "/order",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Orders",
        SubMenuLink: "/order/orders",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Products",
        SubMenuLink: "/order/products",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Receipt Template",
        SubMenuLink: "/order/receipt_template",
        SunMenuIcon: "fa-location-dot",
      },
    ],
  },

  {
    MenuName: "Expenses",
    MenuLink: "/expense",
    MenuIcon: "fa-landmark",
    SubMenus: [
      {
        SubMenuName: "Expense Overview",
        SubMenuLink: "/expense",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Conveyance Overview",
        SubMenuLink: "/expense/conveyance",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Expense Requests",
        SubMenuLink: "/expense/expense_requests",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Conveyance Requests",
        SubMenuLink: "/expense/conveyance_requests",
        SunMenuIcon: "fa-location-dot",
      },
    ],
  },

  {
    MenuName: "ClientsAndSites",
    MenuLink: "/clients_sites/clients",
    MenuIcon: "fa-user-group",
    SubMenus: [
      {
        SubMenuName: "Clients",
        SubMenuLink: "/clients_sites/clients",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Sites",
        SubMenuLink: "/clients_sites/sites",
        SunMenuIcon: "fa-location-dot",
      },
      {
        SubMenuName: "Geofences",
        SubMenuLink: "/clients_sites/geofences",
        SunMenuIcon: "fa-file-word",
      },
    ],
  },

  {
    MenuName: "Reports",
    MenuLink: "/reports",
    MenuIcon: "fa-file",
    SubMenus: [
      {
        SubMenuName: "Download Reports",
        SubMenuLink: "/reports",
        SunMenuIcon: "fa-file-word",
      },
      {
        SubMenuName: "Schedule Reports",
        SubMenuLink: "/reports/schedule",
        SunMenuIcon: "fa-location-dot",
      },
    ],
  },
];





export default Menus;
