import "./sideBar.css";
import logo from "./logo.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { appContext } from "../../App";

export const SideBar = ({ changePage, currentPage }) => {
  let memberShipType = "Elite";
  const { pageMenu, currentMenuPage, changeMenuPage } = useContext(appContext);

  return (
    <div className="sidebar">
      <div className="SBheader">
        <img src={logo} alt="logo" className="logo-img" />
        <h1 className="brand-name">Unolo</h1>
        <span className="member-ship">{memberShipType}</span>
      </div>
      <div className="menu-option">
        <ul>
          {pageMenu.map((menuItem, index) => {
            return (
              <li style={{ listStyle: "none" }} key={index}>
                <Link
                  to={menuItem.MenuLink}
                  className={`menu-item-group ${
                    currentMenuPage === index ? "menu-item-group-active" : ""
                  }`}
                  style={{ textDecoration: "none" }}
                  onClick={() => changeMenuPage(index)}
                >
                  <i className={`fa ${menuItem.MenuIcon} icon-style`}></i>
                  <span>{menuItem.MenuName}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="SBfooter">
        <span className="footer-icon-style">
          <i className="fa fa-circle-question"></i>
        </span>
        <span className="footer-icon-style">
          <i className="fa fa-gear"></i>
        </span>
        <span className="footer-icon-style">
          <i className="fa fa-chevron-left"></i>
        </span>
      </div>
    </div>
  );
};
