import { useContext, useEffect, useState, useRef } from "react";
import { TableGrid } from "../../../components/TableComponent/TableGrid";
import { appContext } from "../../../App";
import "../IndexPage/OraganisationStyle.css";
import { organisationDesignation } from "../../../dataset/organisation";

export const Designation = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  const [showNewDataPage, setShowNewDataPage] = useState(false);

  const [desigData, setDesigData] = useState(organisationDesignation.rows);

  const [total, setTotal] = useState(desigData.length);

  const ref_orgData = useRef();

  useEffect(() => {
    changeSubMenu(currentSubMenu);
    setTotal(desigData.length);
    if (showNewDataPage) ref_orgData.current.focus();
  }, [desigData, currentSubMenu, changeSubMenu, showNewDataPage]);

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  const columns = [
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      sortable: false,
      headerClassName: "grid-header",
      flex: 0,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <i
            className="fa fa-trash trash-icon"
            onClick={(event) => deleteOrgData(cellValues.row.id)}
          />
        </div>
      ),
    },
  ];

  const saveOrgData = () => {
    setDesigData([
      ...desigData,
      {
        id: desigData.length + 1,
        designation: ref_orgData.current.value,
      },
    ]);
    setShowNewDataPage(!showNewDataPage);
  };

  const deleteOrgData = (num) => {
    let newTeamData = desigData.filter((item, index) => {
      return item.id !== num;
    });
    setDesigData(newTeamData);
  };

  const NewOrgData = () => {
    return (
      <div className="org-new-data-page">
        <form
          action=""
          className="org-new-form"
          onSubmit={() => {
            saveOrgData();
          }}
        >
          <label htmlFor="">Add Designation : </label>
          <i
            className="fa fa-close"
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Designation Name"
            ref={ref_orgData}
            required
          />
          <br />
          <div
            style={{
              margin: "auto",
            }}
          >
            <input type="submit" value="Save" className="submit-btn" />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="org_content-container">
      {showNewDataPage && <NewOrgData />}
      <div className="org-header">
        <span>Designations {total > 0 ? "(" + total + ")" : ""}</span>
        <div className="org-inp-container">
          <button
            className="org-add-btn"
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
            }}
          >
            <i className="fa fa-plus" />
            Add
          </button>
        </div>
      </div>
      <div className="org-table-container">
        <TableGrid
          tableRow={desigData}
          tableColumn={columns}
          rowHeight={50}
          initialPageSize={10}
        />
      </div>
    </div>
  );
};
