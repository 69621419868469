import { useContext, useEffect, useState, useRef } from "react";
import { TableGrid } from "../../../components/TableComponent/TableGrid";
import { fieldOfficer } from "../../../dataset/attendance";
import { appContext } from "../../../App";
import "../IndexPage/AttendanceStyle.css";
import {
  PdfExport,
  ExcelExport,
} from "../../../components/ExportComponent/ExportFormat";

export const Roaster = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  const [showNewRoasterPage, setShowNewRoasterPage] = useState(false);

  const [roasterEditIndex, setRoasterEditIndex] = useState(-1);

  const [roasterList, setRoasterList] = useState([
    {
      roasterName: "WO",
      roasterStartTime: "09:30",
      roasterEndtTime: "18:30",
    },
  ]);

  var newObj = [];

  const ref_roasterName = useRef();
  const ref_roasterStartTime = useRef();
  const ref_roasterEndTime = useRef();

  useEffect(() => {
    changeSubMenu(currentSubMenu);
    filterData();
  });

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  const columns = [
    {
      field: "empName",
      headerName: "Employee",
      flex: 1.3,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "Monday",
      headerName: "Monday",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <select name="" id="">
            {roasterList.map((item, index) => {
              return <option>{item.roasterName}</option>;
            })}
          </select>
        </div>
      ),
    },
    {
      field: "Tuesday",
      headerName: "Tuesday",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <select name="" id="">
            {roasterList.map((item, index) => {
              return <option>{item.roasterName}</option>;
            })}
          </select>
        </div>
      ),
    },
    {
      field: "Wednesday",
      headerName: "Wednesday",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <select name="" id="">
            {roasterList.map((item, index) => {
              return <option>{item.roasterName}</option>;
            })}
          </select>
        </div>
      ),
    },
    {
      field: "Thursday",
      headerName: "Thursday",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <select name="" id="">
            {roasterList.map((item, index) => {
              return <option>{item.roasterName}</option>;
            })}
          </select>
        </div>
      ),
    },
    {
      field: "Friday",
      headerName: "Friday",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <select name="" id="">
            {roasterList.map((item, index) => {
              return <option>{item.roasterName}</option>;
            })}
          </select>
        </div>
      ),
    },
    {
      field: "Saturday",
      headerName: "Saturday",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <select name="" id="">
            {roasterList.map((item, index) => {
              return <option>{item.roasterName}</option>;
            })}
          </select>
        </div>
      ),
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   description: "used to edit and modify content",
    //   headerClassName: "grid-header",
    //   sortable: false,
    //   flex: 0,
    //   renderCell: (cellValues) => (
    //     <div className="action-cell">
    //       <i
    //         className="fa fa-trash trash-icon"
    //         // onClick={(event) => deleteOrgData(cellValues.row.id)}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const addNewRoaster = (updateStatus, ind = 0) => {
    if (updateStatus === "CD") {
      roasterList.length > 1
        ? setRoasterList([
            ...roasterList.slice(0, ind),
            ...roasterList.slice(ind + 1),
          ])
        : alert("Sorry! Atleast one Roaster should be present");

      setRoasterEditIndex(-1);
    } else {
      let newRoasterObj = {
        roasterName: ref_roasterName.current.value,
        roasterStartTime: ref_roasterStartTime.current.value,
        roasterEndtTime: ref_roasterEndTime.current.value,
      };

      roasterEditIndex >= 0
        ? setRoasterList([
            ...roasterList.slice(0, roasterEditIndex),
            newRoasterObj,
            ...roasterList.slice(roasterEditIndex + 1),
          ])
        : setRoasterList([...roasterList, newRoasterObj]);

      if (roasterEditIndex >= 0) {
        setRoasterEditIndex(-1);
      }
      setShowNewRoasterPage(!showNewRoasterPage);
    }
  };

  const NewShiftPage = () => {
    return (
      <div className="new-shift-page1">
        <form
          action=""
          className="new-shift-form1"
          onSubmit={() => {
            addNewRoaster();
          }}
        >
          <label htmlFor="">Roaster Name : </label>
          <i
            className="fa fa-close"
            onClick={() => {
              setShowNewRoasterPage(!showNewRoasterPage);
              if (roasterEditIndex >= 0) setRoasterEditIndex(-1);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Roaster Name"
            ref={ref_roasterName}
            defaultValue={
              roasterEditIndex >= 0
                ? roasterList[roasterEditIndex]["roasterName"]
                : ""
            }
            required
          />
          <br />
          <label htmlFor="">Roaster Start Time : </label>
          <br />
          <input
            type="time"
            placeholder="Start time"
            ref={ref_roasterStartTime}
            defaultValue={
              roasterEditIndex >= 0
                ? roasterList[roasterEditIndex]["roasterStartTime"]
                : ""
            }
            required
          />
          <br />
          <label htmlFor="">Roaster End Time : </label>
          <br />
          <input
            type="time"
            placeholder="End time"
            ref={ref_roasterEndTime}
            defaultValue={
              roasterEditIndex >= 0
                ? roasterList[roasterEditIndex]["roasterEndtTime"]
                : ""
            }
            required
          />
          <br />
          <div
            style={{
              margin: "auto",
            }}
          >
            <input type="submit" value="Save" className="submit-btn" />
          </div>
        </form>
      </div>
    );
  };

  const filterData = () => {
    for (let i = 0; i < fieldOfficer.rows.length; i++) {
      let obj = {
        empName: fieldOfficer.rows[i].empName,
        Monday: "WO",
        Tuesday: "WO",
        Wednesday: "WO",
        Thursday: "WO",
        Friday: "WO",
        Saturday: "WO",
      };
      newObj.push(obj);
    }
  };

  return (
    <div className="sh_content-container">
      {showNewRoasterPage && <NewShiftPage />}
      <div className="sh_content-header">
        <div className="sh_content_header_content">
          <span>Create Roaster</span>
          <div className="sh_header_btn_group">
            <button>
              <i className="fa fa-upload" />
              Upload
            </button>
            <ExcelExport
              data={newObj}
              fileName="Roaster"
              sheetName="Roaster Details"
            />
            <PdfExport
              tableHeader={columns}
              tableData={newObj}
              sheetName="Roaster"
              title="Field Officers Roaster Details"
            />
            <button onClick={() => setShowNewRoasterPage(!showNewRoasterPage)}>
              <i className="fa fa-plus" />
              Add
            </button>
          </div>
        </div>
        <div className="sh_shift_container">
          {roasterList.map((shiftItem, index) => {
            return (
              <div
                className="sh_shift_component"
                onClick={() => {
                  setShowNewRoasterPage(!showNewRoasterPage);
                  setRoasterEditIndex(index);
                }}
              >
                <i class="fa fa-sun roaster-icon"></i>
                <div className="sh_shift">
                  <span className="roasterName">{shiftItem.roasterName}</span>
                  <span className="shiftTime">
                    {shiftItem.roasterStartTime +
                      " - " +
                      shiftItem.roasterEndtTime}
                  </span>
                </div>
                <i
                  class="fa fa-close close-icon"
                  onClick={(e) => {
                    setRoasterEditIndex(index);
                    addNewRoaster("CD", index);
                    e.stopPropagation();
                  }}
                ></i>
              </div>
            );
          })}
        </div>
      </div>
      <div className="sh_content_view">
        <TableGrid
          tableRow={fieldOfficer.rows}
          tableColumn={columns}
          rowHeight={60}
          initialPageSize={10}
        />
      </div>
    </div>
  );
};
