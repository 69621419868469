import { useContext, useEffect } from "react";
import { appContext } from "../../../App";
import "../IndexPage/dashboardStyle.css";
import { empNameList } from "../../../dataset/dashboard";

export const TimeLine = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  useEffect(() => {
    changeSubMenu(currentSubMenu);
  });

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  return (
    <div className="content-container">
      <div className="content-header">
        <button className="timeline-btn">
          <i className="fa fa-bars option-icon"></i>View Details
        </button>
        <div className="timeline-search-container">
          <select name="empn-dropdown" id="empn-dropdown">
            {empNameList.map((item, index) => {
              return <option key={index}>{item}</option>;
            })}
          </select>
          {/* <select name="date-dropdown" id="date-dropdown"></select> */}
          <input
            type="date"
            name="date-dropdown"
            id="date-dropdown"
            className="timeline-date-input"
            defaultValue={new Date().toISOString().substr(0, 10)}
          />
          <button className="timeline-btn">Apply</button>
        </div>
        <button className="timeline-btn">
          {" "}
          <i class="fa fa-gear option-icon"></i>Show / Hide
        </button>
      </div>
      <div className="timeline-map-view">
        {/* <h2 style={{ marginTop: 35, marginLeft: 30 }}>Individual {content}</h2> */}
      </div>
    </div>
  );
};
