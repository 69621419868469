import { SideBar } from "../components/SideBar/SideBar";
import { MainContent } from "../components/MainContent/MainContent";
// import { useState } from "react";

export const Base = () => {
  // const [pageSelected, setPageSelected] = useState(0);

  // const ChangePage = (pageIndex) => {
  //   setPageSelected(pageIndex)
  // }

  return (
    <>
      <SideBar />
      <MainContent />
    </>
  );
};
