import React from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import pdf from "../../images/pdf.png";
// import excel from "../../images/excel.png";

export const ExcelExport = ({ data, sheetName, fileName }) => {
  const exportData = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // let buf = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workbook, fileName + ".xlsx");
  };

  return (
    <button
      style={{ backgroundColor: "green" }}
      onClick={() => {
        exportData();
      }}
    >
      <i
        class="fa fa-file-excel"
        aria-hidden="true"
        style={{ fontSize: 19 }}
      ></i>
      Excel
    </button>
  );
};

export const PdfExport = ({ tableHeader, tableData, sheetName, title }) => {
  const exportPdf = () => {
    const doc = new jsPDF();
    doc.text(title, 15, 10);
    doc.autoTable({
      // columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      columns: tableHeader.map((col) => {
        return col.headerName !== "Action"
          ? { header: col.headerName, dataKey: col.field }
          : "";
      }),
      body: tableData,
    });
    doc.save(sheetName + ".pdf");
  };

  return (
    <button
      style={{ backgroundColor: "red" }}
      onClick={() => {
        exportPdf();
      }}
    >
      <i class="fa fa-file-pdf" aria-hidden="true" style={{ fontSize: 19 }}></i>
      Pdf
    </button>
  );
};

export const downloadTemplate = ({ worksheets, fileName }) => {
  const workbook = XLSX.utils.book_new();

  worksheets.map((sheet, index) => {
    return XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(sheet.sheetData),
      sheet.sheetName
    );
  });

  XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

  XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

  XLSX.writeFile(workbook, fileName + ".xlsx");
};
