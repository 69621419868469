import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { TableGrid } from "../../../components/TableComponent/TableGrid";
import { appContext } from "../../../App";
import "../IndexPage/OraganisationStyle.css";
import { organisationRoles } from "../../../dataset/organisation";

export const Roles = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  const [showNewDataPage, setShowNewDataPage] = useState(false);

  const [editIndex, setEditIndex] = useState(-1);

  const [rolesData, setRolesData] = useState(organisationRoles.rows);

  const [total, setTotal] = useState(rolesData.length);

  const [searchRoles, setSearchRoles] = useState({});

  const ref_orgData_name = useRef();
  const ref_orgData_desc = useRef();
  const ref_search_Roles = useRef();

  useEffect(() => {
    changeSubMenu(currentSubMenu);
    setTotal(rolesData.length);
    if (showNewDataPage) ref_orgData_name.current.focus();
  }, [rolesData, currentSubMenu, changeSubMenu, showNewDataPage]);

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  const searchRolesFunc = useCallback(() => {
    setSearchRoles(
      rolesData.filter((item, index) => {
        let searchRegex = new RegExp(ref_search_Roles.current.value, "gi");
        return item.roleName.match(searchRegex);
      })
    );
  }, [rolesData]);

  useEffect(() => {
    searchRolesFunc();
  }, [rolesData, searchRolesFunc]);

  //    getActions: () => [
  //     <GridActionsCellItem icon={<EditIcon />} label="Edit" />,
  //     <GridActionsCellItem icon={<DeleteIcon />} label="Delete" />,
  //   ],

  //   renderHeader: () => (
  //     <div>
  //         <i className ="fa fa-plus"></i>
  //     </div>

  //   ),
  //valueGetter : (params) =>

  //   valueGetter: (params) =>
  //     `${params.row.roleName || ""} ${params.row.description || ""}`,
  //

  // renderCell: () => (
  //     <Stack spacing={1} sx={{ width: 1, py: 1 }}>
  //     </Stack>
  //  }

  const columns = [
    {
      field: "roleName",
      headerName: "Role Name",
      flex: 1,
      headerClassName: "grid-header",
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "grid-header",
      flex: 2,
      editable: true,
    },

    {
      field: "action",
      headerName: "Action",
      headerClassName: "grid-header",
      description: "used to edit and modify content",
      sortable: false,
      flex: 0.17,
      renderCell: (cellValues) => (
        <div className="action-cell">
          <i
            className="fa fa-edit"
            onClick={() => {
              setShowNewDataPage(true);
              setEditIndex(cellValues.row.id);
              // console.log(cellValues.api.getRowIndex(cellValues.row.id));
            }}
          />
          <i
            className="fa fa-trash trash-icon"
            onClick={(event) => deleteOrgData(cellValues.row.id)}
          />
        </div>
      ),
    },
  ];

  const saveOrgData = () => {
    if (editIndex === -1) {
      setRolesData([
        ...rolesData,
        {
          id: rolesData.length + 1,
          roleName: ref_orgData_name.current.value,
          description: ref_orgData_desc.current.value,
        },
      ]);
    } else {
      let newRolesData = [];
      rolesData.map((item, index) => {
        return (newRolesData = [
          ...newRolesData,
          {
            id: item.id,
            roleName:
              item.id === editIndex
                ? ref_orgData_name.current.value
                : item.roleName,
            description:
              item.id === editIndex
                ? ref_orgData_desc.current.value
                : item.description,
          },
        ]);
      });
      setRolesData(newRolesData);
      setEditIndex(-1);
      // setShowNewDataPage(!showNewDataPage);
    }
    setShowNewDataPage(!showNewDataPage);
  };

  const deleteOrgData = (num) => {
    let newTeamData = rolesData.filter((item, index) => {
      return item.id !== num;
    });
    setRolesData(newTeamData);
  };

  const NewOrgData = () => {
    return (
      <div className="org-new-data-page">
        <form
          action=""
          className="org-new-form"
          onSubmit={() => {
            saveOrgData();
          }}
        >
          <label htmlFor="">
            {editIndex >= 0 ? "Edit Role :" : "Add New Role :"}
          </label>
          <i
            className="fa fa-close"
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
              setEditIndex(-1);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Role Name"
            ref={ref_orgData_name}
            required
            defaultValue={
              editIndex >= 0 ? rolesData[editIndex - 1]["roleName"] : ""
            }
          />
          <br />
          <br />
          <input
            type="text"
            placeholder="Role Descriptio"
            ref={ref_orgData_desc}
            required
            defaultValue={
              editIndex >= 0 ? rolesData[editIndex - 1]["description"] : ""
            }
          />
          <br />
          <div
            style={{
              margin: "auto",
            }}
          >
            <input type="submit" value="Save" className="submit-btn" />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="org_content-container">
      {showNewDataPage && <NewOrgData />}
      <div className="org-header">
        <span>Roles {total > 0 ? "(" + total + ")" : ""}</span>
        <div className="org-inp-container">
          <input
            type="search"
            placeholder="Search Here"
            ref={ref_search_Roles}
            onChange={searchRolesFunc}
          />
          <button
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
            }}
          >
            <i className="fa fa-plus" />
            Add
          </button>
        </div>
      </div>
      <div className="org-table-container">
        <TableGrid
          tableRow={searchRoles}
          tableColumn={columns}
          rowHeight={50}
          initialPageSize={10}
        />
      </div>
    </div>
  );
};
