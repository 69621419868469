import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { appContext } from "../../../App";
import "../IndexPage/OraganisationStyle.css";
import { TableGrid } from "../../../components/TableComponent/TableGrid";
import { organisationFieldOfficers } from "../../../dataset/organisation";
import { fieldOfficer as officerData } from "../../../dataset/attendance";

// import XLSX from "xlsx";
import {
  ExcelExport,
  PdfExport,
  downloadTemplate,
} from "../../../components/ExportComponent/ExportFormat";

const templateColumn = [
  "EmployeeName",
  "EmployeeId",
  "MobileNo",
  "MailID",
  "JoiningDate",
  "ReportingOfficer",
];

export const FieldExecutives = ({ currentSubMenu, content }) => {
  const { pageMenu, currentMenuPage, changeSubMenu } = useContext(appContext);

  const [editIndex, setEditIndex] = useState(-1);

  const [showNewDataPage, setShowNewDataPage] = useState(false);

  const [showUploadPage, setShowUploadPage] = useState(false);

  const [fieldOfficer, setFieldOfficer] = useState(
    organisationFieldOfficers.rows
  );

  const [searchFieldOfficer, setSearchFieldOfficer] = useState({});

  const [total, setTotal] = useState(fieldOfficer.length);

  const ref_orgData_empID = useRef();
  const ref_orgData_name = useRef();
  const ref_orgData_mobNo = useRef();
  const ref_orgData_mail = useRef();
  const ref_orgData_reporting = useRef();
  const ref_orgData_joinDate = useRef();
  const ref_search_empn = useRef();
  //const ref_orgData_mobNo = useRef();

  let newObj = [];

  useEffect(() => {
    changeSubMenu(currentSubMenu);
    setTotal(fieldOfficer.length);
  }, [fieldOfficer, currentSubMenu, changeSubMenu]);

  useEffect(() => {
    document.title =
      pageMenu[currentMenuPage]["SubMenus"][currentSubMenu]["SubMenuName"] +
      " | " +
      pageMenu[currentMenuPage]["MenuName"];
  }, [pageMenu, currentMenuPage, currentSubMenu]);

  useEffect(() => {
    filterData();
  });

  const filterData = () => {
    for (let i = 0; i < officerData.rows.length; i++) {
      let obj = {
        empName: officerData.rows[i].empName,
      };
      newObj.push(obj);
    }
  };

  const searchEmp = useCallback(() => {
    setSearchFieldOfficer(
      fieldOfficer.filter((item, index) => {
        let searchRegex = new RegExp(ref_search_empn.current.value, "gi");
        return item.empName.match(searchRegex);
      })
    );
  }, [fieldOfficer]);

  useEffect(() => {
    searchEmp();
  }, [fieldOfficer, searchEmp]);

  const sliceEmpName = (name) => {
    let newl = 0;
    for (let a = 0; a < name.length; a++) {
      if (name[a] === " ") {
        newl = a + 1;
        break;
      }
    }
    return newl > 0 ? name[0] + name[newl] : name[0] + name[1];
  };

  const columns = [
    {
      field: "empName",
      headerName: "Employee",
      flex: 0.85,
      headerClassName: "grid-header",
      editable: false,
      renderCell: (cellValues) => (
        <div className="org_component">
          <div className="org-name-icon">
            <span>
              {editIndex >= 0 || sliceEmpName(cellValues.row.empName)}
            </span>
          </div>
          <div className="ord-emp-det">
            <span className="emp-name">{cellValues.row.empName}</span>
            <span className="emp-mail">{cellValues.row.mail}</span>
          </div>
        </div>
      ),
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "employeeId",
      headerName: "Employee ID",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "empMobNo",
      headerName: "Mobile Number",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "joiningDate",
      headerName: "Joining Date",
      type: "Date",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "reportingEmp",
      headerName: "Reporting Manager",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "empTeam",
      headerName: "Team",
      flex: 0.6,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "empDesig",
      headerName: "Designation",
      flex: 0.6,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      sortable: false,
      headerClassName: "grid-header",
      flex: 0.25,
      // headerClassName: 'super-app-theme--header',
      // headerAlign: 'center',
      renderCell: (cellValues) => (
        <div className="action-cell">
          <i
            className="fa fa-edit"
            onClick={() => {
              setShowNewDataPage(true);
              setEditIndex(cellValues.row.id);
              // console.log(cellValues.api.getRowIndex(cellValues.row.id));
            }}
          />
          <i
            className="fa fa-trash trash-icon"
            onClick={(event) => deleteOrgData(cellValues.row.id)}
          />
        </div>
      ),
    },
  ];

  const saveOrgData = () => {
    if (editIndex === -1) {
      setFieldOfficer([
        ...fieldOfficer,
        {
          id: fieldOfficer.length + 1,
          empName: ref_orgData_name.current.value,
          userId: "mx12kerx",
          employeeId: ref_orgData_empID.current.value,
          empMobNo: ref_orgData_mobNo.current.value,
          mail: ref_orgData_mail.current.value,
          reportingEmp: ref_orgData_reporting.current.value,
          empTeam: "Default",
          empDesig: "None",
          joiningDate: ref_orgData_joinDate.current.value,
        },
      ]);
    } else {
      let newRolesData = [];
      fieldOfficer.map((item, index) => {
        return (newRolesData = [
          ...newRolesData,
          {
            id: item.id,
            empName:
              item.id === editIndex
                ? ref_orgData_name.current.value
                : item.empName,
            userId: "mx12kerx",
            employeeId:
              item.id === editIndex
                ? ref_orgData_empID.current.value
                : item.employeeId,
            empMobNo:
              item.id === editIndex
                ? ref_orgData_mobNo.current.value
                : item.empMobNo,
            mail:
              item.id === editIndex
                ? ref_orgData_mail.current.value
                : item.mail,
            reportingEmp:
              item.id === editIndex
                ? ref_orgData_reporting.current.value
                : item.reportingEmp,
            empTeam: "Default",
            empDesig: "None",
            joiningDate:
              item.id === editIndex
                ? ref_orgData_joinDate.current.value
                : item.joiningDate,
          },
        ]);
      });
      setFieldOfficer(newRolesData);
      setEditIndex(-1);
    }
    searchEmp();
    setShowNewDataPage(!showNewDataPage);
  };

  const deleteOrgData = (num) => {
    let newTeamData = fieldOfficer.filter((item, index) => {
      return item.id !== num;
    });
    setFieldOfficer(newTeamData);
  };

  const NewOrgData = () => {
    return (
      <div className="org-new-emp-page">
        <form
          action=""
          className="org-new-emp-form"
          onSubmit={() => {
            saveOrgData();
          }}
        >
          <label htmlFor="">
            {editIndex >= 0 ? "Edit User :" : "Add User :"}
          </label>
          <i
            className="fa fa-close"
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
              setEditIndex(-1);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Name"
            ref={ref_orgData_name}
            required
            defaultValue={
              editIndex >= 0 ? fieldOfficer[editIndex - 1]["empName"] : ""
            }
            onInput={(e) => {
              var input = e.target;
              var start = input.selectionStart;
              var end = input.selectionEnd;
              input.value = input.value.toLocaleUpperCase();
              input.setSelectionRange(start, end);
            }}
          />
          <br />
          <input
            type="text"
            placeholder="Employee ID"
            ref={ref_orgData_empID}
            required
            defaultValue={
              editIndex >= 0 ? fieldOfficer[editIndex - 1]["employeeId"] : ""
            }
            onInput={(e) => {
              var input = e.target;
              var start = input.selectionStart;
              var end = input.selectionEnd;
              input.value = input.value.toLocaleUpperCase();
              input.setSelectionRange(start, end);
            }}
          />
          <br />
          <input
            type="number"
            placeholder="Mobile No."
            ref={ref_orgData_mobNo}
            required
            defaultValue={
              editIndex >= 0 ? fieldOfficer[editIndex - 1]["empMobNo"] : ""
            }
            // onInput={(e) => {
            //   var input = e.target;
            //   var start = input.selectionStart;
            //   var end = input.selectionEnd;
            //   input.value = input.value.toLocaleUpperCase();
            //   input.setSelectionRange(start, end);
            // }}
          />
          <br />
          <input
            type="email"
            placeholder="Mail ID"
            ref={ref_orgData_mail}
            required
            defaultValue={
              editIndex >= 0 ? fieldOfficer[editIndex - 1]["mail"] : ""
            }
            // onInput={(e) => {
            //   var input = e.target;
            //   var start = input.selectionStart;
            //   var end = input.selectionEnd;
            //   input.value = input.value.toLocaleUpperCase();
            //   input.setSelectionRange(start, end);
            // }}
          />
          <br />
          <select
            ref={ref_orgData_reporting}
            defaultValue={
              editIndex >= 0 ? fieldOfficer[editIndex - 1]["reportingEmp"] : ""
            }
          >
            {organisationFieldOfficers.rows.map((item, index) => {
              return (
                <option key={index} value={item.empName}>
                  {item.empName}
                </option>
              );
            })}
          </select>
          <input
            type="date"
            placeholder="Joining Date"
            ref={ref_orgData_joinDate}
            defaultValue={
              editIndex >= 0
                ? fieldOfficer[editIndex - 1]["joiningDate"]
                : new Date().toISOString().substr(0, 10)
            }
            // onInput={(e) => {
            //   var input = e.target;
            //   var start = input.selectionStart;
            //   var end = input.selectionEnd;
            //   input.value = input.value.toLocaleUpperCase();
            //   input.setSelectionRange(start, end);
            // }}
          />
          <br />
          <br />
          <input type="submit" value="Save" className="submit-btn" />
        </form>
      </div>
    );
  };

  const UploadData = () => {
    let coldata = [];
    templateColumn.map((item, index) => {
      let obj = {};
      obj[item] = "";
      coldata.push(obj);
      return "";
    });

    return (
      <div className="upload-page-container">
        <div className="upload-page">
          <i
            className="fa fa-close header-close-icon"
            onClick={() => {
              setShowUploadPage(!showUploadPage);
              setEditIndex(-1);
            }}
          />
          <div className="upload-header">
            <h2>Bulk Upload</h2>
          </div>
          <div>
            <button
              onClick={() =>
                downloadTemplate({
                  worksheets: [
                    {
                      sheetName: "New Officers",
                      sheetData: coldata,
                    },
                    {
                      sheetName: "Existing Officers",
                      sheetData: newObj,
                    },
                  ],
                  fileName: "Upload Template",
                })
              }
            >
              <i className="fa fa-download icons" />
              Template
            </button>
            <button>
              <i className="fa fa-upload icons" />
              Upload
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="org_content-container">
      {showNewDataPage && <NewOrgData />}
      {showUploadPage && <UploadData />}
      <div className="org-header">
        <span>Field Executives {total > 0 ? "(" + total + ")" : ""}</span>
        <div className="org-inp-container org-fe-btn-group">
          <input
            type="search"
            placeholder="Search Here"
            onChange={searchEmp}
            ref={ref_search_empn}
          />
          <button
            onClick={() => {
              setShowUploadPage(!showUploadPage);
            }}
          >
            <i className="fa fa-upload" />
            Upload
          </button>
          <ExcelExport
            data={fieldOfficer}
            sheetName="Field Officers"
            fileName="Field Officers"
          />
          <PdfExport
            tableHeader={columns}
            tableData={fieldOfficer}
            sheetName="Field Officers"
            title="Field Officers Details"
          />
          <button
            onClick={() => {
              setShowNewDataPage(!showNewDataPage);
            }}
          >
            <i className="fa fa-plus" />
            Add
          </button>
        </div>
      </div>
      <div className="org-table-container">
        <TableGrid
          tableRow={searchFieldOfficer}
          tableColumn={columns}
          rowHeight={60}
          initialPageSize={50}
        />
      </div>
    </div>
  );
};
