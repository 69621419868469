import { useContext, useEffect } from "react";
import { appContext } from "../../../App";
import { Outlet, Link } from "react-router-dom";

export const Expenses = ({ Menus }) => {
  const { pageMenu, changeMenuPage, currentSubMenu, changeSubMenu } =
    useContext(appContext);

  useEffect(() => {
    changeMenuPage(6);
  });

  return (
    <>
      <div className="content-menu-group">
        {pageMenu[6] &&
          pageMenu[6]["SubMenus"].map((item, index) => {
            return (
              <Link
                to={item.SubMenuLink}
                onClick={() => {
                  changeSubMenu(index);
                }}
                className={`content-menu-btn ${
                  currentSubMenu === index ? "content-menu-btn-active" : ""
                }`}
              >
                <i class={`fa ${item.SunMenuIcon} sub-menu-icon`}></i>
                <span>{item.SubMenuName}</span>
              </Link>
            );
          })}
      </div>

      <Outlet />

      {/* <SubPage content="Sub Menu Page" /> */}
    </>
  );
  // return (
  //   <>
  //     <h2 style={{ marginTop: 15}}>Expense Page</h2>
  //   </>
  // );
};
