export const organisationRoles = {
  columns: [
    {
      field: "roleName",
      headerName: "Role Name",
      flex: 1,
      headerClassName: "grid-header",
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "grid-header",
      flex: 2,
      editable: true,
    },

    {
      field: "action",
      headerName: "Action",
      headerClassName: "grid-header",
      description: "used to edit and modify content",
      sortable: false,
      flex: 0.17,
      renderCell: () => (
        <div className="action-cell">
          <i className="fa fa-edit" />
          <i className="fa fa-trash trash-icon" />
        </div>
      ),
    },
  ],

  rows: [
    {
      id: 1,
      roleName: "Legacy Manager",
      description:
        "All access except shift/roaster template and access control",
    },
    {
      id: 2,
      roleName: "Global Read",
      description: "Read access to all modules",
    },
    { id: 3, roleName: "Accounts", description: "Download reports only" },
    {
      id: 4,
      roleName: "Company Admin",
      description: "Super user who has access to all modules",
    },
    {
      id: 5,
      roleName: "HR",
      description: "Read and write access to Field Executives module",
    },
  ],
};

export const organisationDesignation = {
  columns: [
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      sortable: false,
      headerClassName: "grid-header",
      flex: 0,
      renderCell: () => (
        <div className="action-cell">
          <i className="fa fa-trash trash-icon" />
        </div>
      ),
    },
  ],

  rows: [
    {
      id: 1,
      designation: "None",
    },
  ],
};

export const organisationTeams = {
  columns: [
    {
      field: "teamName",
      headerName: "Team Name",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      headerClassName: "grid-header",
      sortable: false,
      flex: 0,
      renderCell: () => (
        <div className="action-cell">
          <i className="fa fa-trash trash-icon" />
        </div>
      ),
    },
  ],

  rows: [
    {
      id: 11,
      teamName: "Default",
    },
    {
      id: 12,
      teamName: "EP Team",
    },
    {
      id: 13,
      teamName: "RO Vehicles",
    },
  ],
};

export const organisationAdmins = {
  columns: [
    {
      field: "empName",
      headerName: "Employee",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "empVisibility",
      headerName: "Executive Visibility",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "reportingEmp",
      headerName: "Reports to",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "fieldExecutives",
      headerName: "Field Executives",
      flex: 1,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      sortable: false,
      headerClassName: "grid-header",
      flex: 0.25,
      // headerClassName: 'super-app-theme--header',
      // headerAlign: 'center',
      renderCell: () => (
        <div className="action-cell">
          <i className="fa fa-edit" />
          <i className="fa fa-trash trash-icon" />
        </div>
      ),
    },
  ],

  rows: [
    {
      id: 1,
      empName: "KCP RO",
      role: "Company Admin",
      mail: "uvvnchary@kcp.co.in",
      empVisibility: "ALL",
      reportingEmp: "-",
      fieldExecutives: 2,
    },

    {
      id: 2,
      empName: "K RAMAKRISHNA",
      role: "Legacy Manager",
      mail: "ramakrihna.k@kcp.co.in",
      empVisibility: "K RAMAKRISHNA",
      reportingEmp: "KCP RO",
      fieldExecutives: 19,
    },
    {
      id: 3,
      empName: "N KIRANKUMAR",
      role: "Legacy Manager",
      mail: "agmgnt.kcp@gmail.com",
      empVisibility: "N KIRANKUMAR",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 6,
    },
    {
      id: 4,
      empName: "K SIVAKUMAR",
      role: "Legacy Manager",
      mail: "sivacivil473@gmail.com",
      empVisibility: "K SIVAKUMAR",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 5,
      empName: "PAVAN KUMAR PV",
      role: "Legacy Manager",
      mail: "bangalore.kcp@gmail.com",
      empVisibility: "PAVAN KUMAR PV",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 0,
    },
    {
      id: 6,
      empName: "BIJOMON K X",
      role: "Legacy Manager",
      mail: "bijomonkxr@gmail.com",
      empVisibility: "BIJOMON K X",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 7,
      empName: "KRISHNARAJ",
      role: "Legacy Manager",
      mail: "chennai.kcp@gmail.com",
      empVisibility: ".KRISHNARAJ",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 0,
    },
    {
      id: 8,
      empName: "M G RAM PRABHU",
      role: "Legacy Manager",
      mail: "coimbatore.kcp@gmail.com",
      empVisibility: "M G RAM PRABHU",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 9,
      empName: "VADDE SRINIVASA RAO",
      role: "Legacy Manager",
      mail: "dgmgnt@kcp.co.in",
      empVisibility: "VADDE SRINIVASA RAO",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 10,
      empName: "N V S N RAJU",
      role: "Legacy Manager",
      mail: "eastgodawari.kcp@gmail.com",
      empVisibility: "N V S N RAJU",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 3,
    },
    {
      id: 11,
      empName: "JAY KUMAR P",
      role: "Legacy Manager",
      mail: "hyderabad.kcp@gmail.com",
      empVisibility: "JAY KUMAR P",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 12,
      empName: "I RAJA SEKHAR",
      role: "Legacy Manager",
      mail: "hydwest.kcp@gmail.com",
      empVisibility: "I RAJA SEKHAR",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 3,
    },
    {
      id: 13,
      empName: "V LINGAIAH",
      role: "Legacy Manager",
      mail: "lingaiah5v@gmail.com",
      empVisibility: "V LINGAIAH",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 14,
      empName: "KURANGI NAGESHWARARAO",
      role: "Legacy Manager",
      mail: "rao993@gmail.com",
      empVisibility: "KURANGI NAGESHWARARAO",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 0,
    },
    {
      id: 15,
      empName: "MANIVEL KRISHNAN",
      role: "Legacy Manager",
      mail: "krishmanivel@yahoo.com",
      empVisibility: "MANIVEL KRISHNAN",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 5,
    },
    {
      id: 16,
      empName: "NARAYANAN S",
      role: "Legacy Manager",
      mail: "narayanan.raju@yahoo.com",
      empVisibility: "NARAYANAN S",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 5,
    },
    {
      id: 17,
      empName: "P SURESH BABU",
      role: "Legacy Manager",
      mail: "villupuram.kcp@gmail.com",
      empVisibility: "P SURESH BABU",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 18,
      empName: "E BANGARU MOULI",
      role: "Legacy Manager",
      mail: "nalgonda.kcp@gmail.com",
      empVisibility: "E BANGARU MOULI",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 3,
    },
    {
      id: 19,
      empName: "NAZEER SHEIK",
      role: "Legacy Manager",
      mail: "vijayawada.kcp@gmail.com",
      empVisibility: "NAZEER SHEIK",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 20,
      empName: "AZEEM HAFEEZ M",
      role: "Legacy Manager",
      mail: "katpadi.kcp@gmail.com",
      empVisibility: "AZEEM HAFEEZ M",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 4,
    },
    {
      id: 21,
      empName: "BALA VENKATESWARA RAO S",
      role: "Legacy Manager",
      mail: "nellore.kcp@gmail.com",
      empVisibility: "BALA VENKATESWARA RAO S",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 22,
      empName: "S AUSTIN NETHAJI",
      role: "Legacy Manager",
      mail: "nethajias86@gmail.com",
      empVisibility: "S AUSTIN NETHAJI",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 0,
    },
    {
      id: 23,
      empName: "SRINIVASA REDDY B",
      role: "Legacy Manager",
      mail: "prakasm.kcp@gmail.com",
      empVisibility: "SRINIVASA REDDY B",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 0,
    },
    {
      id: 24,
      empName: "RADHAKRISHNA MURTHY G",
      role: "Legacy Manager",
      mail: "radhakrishnamurthy.gubba@gmail.com",
      empVisibility: "RADHAKRISHNA MURTHY G",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 3,
    },
    {
      id: 25,
      empName: "ARUN KUMAR",
      role: "Legacy Manager",
      mail: "arungadireddy@gmail.com",
      empVisibility: "ARUN KUMAR",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 2,
    },
    {
      id: 26,
      empName: "SENTHIL KUMAR MARIMUTHU",
      role: "Legacy Manager",
      mail: "senthil.mecheri@gmail.com",
      empVisibility: "SENTHIL KUMAR MARIMUTHU",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 1,
    },
    {
      id: 27,
      empName: "K HARANADH",
      role: "Legacy Manager",
      mail: "srikakulam.kcp@gmail.com",
      empVisibility: "K HARANADH",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 2,
    },
    {
      id: 28,
      empName: "REDDY VARA KUMAR B",
      role: "Legacy Manager",
      mail: "varakumar_001@yahoo.com",
      empVisibility: "REDDY VARA KUMAR B",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 2,
    },
    {
      id: 29,
      empName: "G HEMANTHKUMAR",
      role: "Legacy Manager",
      mail: "vijayawada@kcp.co.in",
      empVisibility: "G HEMANTHKUMAR",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 5,
    },
    {
      id: 30,
      empName: "J VIJAYA KUMAR",
      role: "Legacy Manager",
      mail: "vizag.kcp@gmail.com",
      empVisibility: "J VIJAYA KUMAR",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 5,
    },
    {
      id: 31,
      empName: "B MAHENDRA RAJU",
      role: "Legacy Manager",
      mail: "westgodawari.kcp@gmail.com",
      empVisibility: "B MAHENDRA RAJU",
      reportingEmp: "K RAMAKRISHNA",
      fieldExecutives: 3,
    },
  ],
};

export const organisationFieldOfficers = {
  columns: [
    {
      field: "empName",
      headerName: "Employee",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "employeeId",
      headerName: "Employee ID",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "empMobNo",
      headerName: "Mobile Number",
      flex: 0.7,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "joiningDate",
      headerName: "Joining Date",
      type: "Date",
      flex: 0.5,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "reportingEmp",
      headerName: "Reporting Manager",
      flex: 0.8,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "empTeam",
      headerName: "Team",
      flex: 0.6,
      headerClassName: "grid-header",
      editable: false,
    },
    {
      field: "empDesig",
      headerName: "Designation",
      flex: 0.6,
      headerClassName: "grid-header",
      editable: false,
    },

    {
      field: "action",
      headerName: "Action",
      description: "used to edit and modify content",
      sortable: false,
      headerClassName: "grid-header",
      flex: 0.25,
      // headerClassName: 'super-app-theme--header',
      // headerAlign: 'center',
      renderCell: (cellValues) => (
        <div className="action-cell">
          <i className="fa fa-edit" />
          <i className="fa fa-trash trash-icon" />
        </div>
      ),
    },
  ],

  rows: [
    {
      id: 1,
      empName: "KRISHNARAJ",
      userId: "xo5cw5qa",
      employeeId: "MT6285",
      empMobNo: "9100092338",
      mail: "chennai.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 2,
      empName: "KOTHA SHIVA KUMAR",
      userId: "305xh9ad",
      employeeId: "MT6346",
      empMobNo: "9100111775",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "ARUN KUMAR G",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 3,
      empName: "KORNU RAJASEKAR",
      userId: "g4vfvezl",
      employeeId: "MT6345",
      empMobNo: "9177335584",
      mail: "prakasam.kcp@gmail.com",
      reportingEmp: "NAZEER SHEIK",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 4,
      empName: "EEPURI SIVA",
      userId: "hvncw783",
      employeeId: "MT6344",
      empMobNo: "9100092451",
      mail: "prakasam.kcp@gmail.com",
      reportingEmp: "E BANGARU MOULI",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 5,
      empName: "VEDANTAM RANGANATH",
      userId: "v9nc4z6o",
      employeeId: "MT6343",
      empMobNo: "8008887821",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "Radhakrishna Murthy G",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 6,
      empName: "SEETHABATHI SIVAJI",
      userId: "utv5h7ii",
      employeeId: "MT6342",
      empMobNo: "8754582288",
      mail: "salem.kcp@gmail.com",
      reportingEmp: "SENTHILKUMAR MARIMUTHU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 7,
      empName: "KRISHNAMOORTHY KANDASAMY",
      userId: "ntae6ug9",
      employeeId: "MT6341",
      empMobNo: "9600066804",
      mail: "coimbatore.kcp@gmail.com",
      reportingEmp: "M G RAM PRABHU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 8,
      empName: "GANGURI NARESH",
      userId: "sd35cjjc",
      employeeId: "MT6338",
      empMobNo: "8008887843",
      mail: "vijayawada.kcp@gmail.com",
      reportingEmp: "G HEMANTHKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 9,
      empName: "SRIKANTH THATI",
      userId: "8g122u9o",
      employeeId: "MT6336",
      empMobNo: "9177707418",
      mail: "THATISRIKANTH1099@GMAIL.COM",
      reportingEmp: "N KIRANKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 10,
      empName: "K S T S NAGANARAYANA",
      userId: "nyo4rz99",
      employeeId: "MT6334",
      empMobNo: "7995777169",
      mail: "westgodavari.kcp@gmail.com",
      reportingEmp: "B MAHENDRA RAJU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 11,
      empName: "VISHNUKUMAR PANDIAN",
      userId: "y8bxb568",
      employeeId: "MT6333",
      empMobNo: "7338715252",
      mail: "madurai.kcp@gmail.com",
      reportingEmp: "MANIVEL KRISHNAN",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 12,
      empName: "KALLA VENKATA NAGESWARA RAO",
      userId: "7rhzapc2",
      employeeId: "MT6332",
      empMobNo: "9177335563",
      mail: "westgodavari.kcp@gmail.com",
      reportingEmp: "B MAHENDRA RAJU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 13,
      empName: "P NAVEEN KUMAR",
      userId: "qocodqfq",
      employeeId: "MT6330",
      empMobNo: "8861209021",
      mail: "bangalore.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 14,
      empName: "MANIVEL KRISHNAN",
      userId: "crpb41ag",
      employeeId: "MT6328",
      empMobNo: "7338815008",
      mail: "KRISHMANIVEL@YAHOO.COM",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 15,
      empName: "PENDYALA RAVIKUMAR",
      userId: "ixsy66qg",
      employeeId: "MT6326",
      empMobNo: "8978633355",
      mail: "RAVIKUMAR3202@GMAIL.COM",
      reportingEmp: "N KIRANKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 16,
      empName: "UDI KUMAR",
      userId: "lgsxsplm",
      employeeId: "MT6325",
      empMobNo: "9177707401",
      mail: "KUMARUDI999@GMAIL.COM",
      reportingEmp: "k Siva Kumar",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 17,
      empName: "K PARASHU RAMUDU",
      userId: "zpwqqx7j",
      employeeId: "MT6320",
      empMobNo: "7995777144",
      mail: "hydwest.kcp@gmail.com",
      reportingEmp: "V LINGAIAH",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 18,
      empName: "M G RAM PRABHU",
      userId: "thh6zfkn",
      employeeId: "MT6319",
      empMobNo: "9100092454",
      mail: "coimbatore.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 19,
      empName: "AZEEM HAFEEZ M",
      userId: "v2rbyj2b",
      employeeId: "MT6318",
      empMobNo: "7540095050",
      mail: "katpadi.kcp@gmail.com",
      reportingEmp: "MANIVEL KRISHNAN",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 20,
      empName: "KURANGI NAGESHWARARAO",
      userId: "dprrq5r9",
      employeeId: "MT6317",
      empMobNo: "9100064661",
      mail: "hydwest.kcp@gmail.com",
      reportingEmp: "I RAJA SEKHAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 21,
      empName: "P PAUL ARPUTHARAJ",
      userId: "tr74nr00",
      employeeId: "MT6313",
      empMobNo: "8754594736",
      mail: "chennai@kcp.co.in",
      reportingEmp: "NARAYANAN S",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 22,
      empName: "NANDAVARAPU ANJANEYULU",
      userId: "7ns3iyn6",
      employeeId: "MT6312",
      empMobNo: "9701626510",
      mail: "cement.guntur@kcp.co.in",
      reportingEmp: "N KIRANKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 23,
      empName: "SENTHILKUMAR MARIMUTHU",
      userId: "lzmrgrau",
      employeeId: "MT6311",
      empMobNo: "9791107863",
      mail: "salem.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 24,
      empName: "DONE RAJESH BABU",
      userId: "okfyfj8o",
      employeeId: "MT6310",
      empMobNo: "7995511249",
      mail: "cement.guntur@kcp.co.in",
      reportingEmp: "N KIRANKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 25,
      empName: "SARANKUMAR GOPAL",
      userId: "r2c5hdzm",
      employeeId: "MT6308",
      empMobNo: "9100092453",
      mail: "katpadi.kcp@gmail.com",
      reportingEmp: "AZEEM HAFEEZ M",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 26,
      empName: "SUDALAIMUTHU NANTHAKUMAR",
      userId: "nezpziwv",
      employeeId: "MT6307",
      empMobNo: "8056040928",
      mail: "coimbatore.kcp@gmail.com",
      reportingEmp: "MANIVEL KRISHNAN",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 27,
      empName: "BUDATI SRI SIVA NAGENDRA KUMAR",
      userId: "1314atky",
      employeeId: "MT6305",
      empMobNo: "7995577439",
      mail: "vijayawada@kcp.co.in",
      reportingEmp: "G HEMANTHKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 28,
      empName: "DIVYA VENKATAIAH",
      userId: "pgcuh08l",
      employeeId: "MT6304",
      empMobNo: "7995577438",
      mail: "prakasam.kcp@gmail.com",
      reportingEmp: "E BANGARU MOULI",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 29,
      empName: "THOTA NAGA SRINIVASARAO",
      userId: "nbzgdek1",
      employeeId: "MT6303",
      empMobNo: "7995577437",
      mail: "eastgodavari.kcp@gmail.com",
      reportingEmp: "N V S N RAJU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 30,
      empName: "BALIVADA SAI RAM SOWRYA",
      userId: "lr6ruelr",
      employeeId: "MT6298",
      empMobNo: "9177220002",
      mail: "vizag.kcp@gmail.com",
      reportingEmp: "J VIJAY KUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 31,
      empName: "SIVAKUMAR THYAGARAJAN",
      userId: "b9oycxzo",
      employeeId: "MT6297",
      empMobNo: "9100092454",
      mail: "SIVA2CONTACT@REDIFFMAIL.COM",
      reportingEmp: "AZEEM HAFEEZ M",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 32,
      empName: "K Anji Reddy",
      userId: "zbfjj8pm",
      employeeId: "MT6295",
      empMobNo: "8008153454",
      mail: "chinnaanji.k@gmail.com",
      reportingEmp: "VADDE SRINIVASA RAO",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 33,
      empName: "NARAYANAN S",
      userId: "ehzm7nkn",
      employeeId: "MT6294",
      empMobNo: "9100092455",
      mail: "NARAYANAN.RAJU@YAHOO.COM",
      reportingEmp: "MANIVEL KRISHNAN",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 34,
      empName: "GANDHI RAJAN SIVA KUMAR",
      userId: "qzbze3l9",
      employeeId: "MT6293",
      empMobNo: "7338716060",
      mail: "gandhirajan70@gmail.com",
      reportingEmp: "AZEEM HAFEEZ M",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 35,
      empName: "JITHIN JOHNSON",
      userId: "v2pwsdss",
      employeeId: "MT6291",
      empMobNo: "9100811225",
      mail: "jj.madathil@gmail.com",
      reportingEmp: "BIJOMON K X",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 36,
      empName: "N Humesh",
      userId: "2vd0nvi7",
      employeeId: "MT6289",
      empMobNo: "8978093434",
      mail: "umeshnamthabad@gmail.com",
      reportingEmp: "Radhakrishna Murthy G",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 37,
      empName: "S AUSTIN NETHAJI",
      userId: "g3yxtw43",
      employeeId: "MT6288",
      empMobNo: "7550086689",
      mail: "NETHAJIAS86@GMAIL.COM",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 38,
      empName: "K. Siva Kumar",
      userId: "8pd9o1bp",
      employeeId: "MT6287",
      empMobNo: "9100977706",
      mail: "cement.guntur@kcp.co.in",
      reportingEmp: "N KIRANKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 39,
      empName: "G. Radhakrishna Murthy",
      userId: "3twy31be",
      employeeId: "MT6286",
      empMobNo: "9849697834",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 40,
      empName: "B MELVIN  BOSE",
      userId: "dgqt92qq",
      employeeId: "MT6284",
      empMobNo: "8754472595",
      mail: "chennai.kcp@gmail.com",
      reportingEmp: "NARAYANAN S",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 41,
      empName: "A THIRUPATHI",
      userId: "xtj5yyo4",
      employeeId: "MT6283",
      empMobNo: "9100064633",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "JAY KUMAR P",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 42,
      empName: "V MANOJ",
      userId: "nchvoqav",
      employeeId: "MT6274",
      empMobNo: "7330660058",
      mail: "eastgodavari.kcp@gmail.com",
      reportingEmp: "N V S N RAJU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 43,
      empName: "N V. NAGESWARA RAO",
      userId: "vpngf59d",
      employeeId: "MT6273",
      empMobNo: "9849697829",
      mail: "cement.guntur@kcp.co.in",
      reportingEmp: "N KIRANKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 44,
      empName: "K X BIJOMON",
      userId: "6493b780",
      employeeId: "MT6268",
      empMobNo: "9645090145",
      mail: "bijomonkxr@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 45,
      empName: "V N RAJKUMAR",
      userId: "diuhmdf0",
      employeeId: "MT6267",
      empMobNo: "8008887946",
      mail: "eastgodavari.kcp@gmail.com",
      reportingEmp: "N V S N RAJU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 46,
      empName: "P V PAVAN KUMAR",
      userId: "s7bk31o8",
      employeeId: "MT6261",
      empMobNo: "8861209023",
      mail: "bangalore.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 47,
      empName: "B U G S MANI KRISHNA",
      userId: "li2tui0r",
      employeeId: "MT6258",
      empMobNo: "9100959789",
      mail: "vijayawada.kcp@gmail.com",
      reportingEmp: "G HEMANTHKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 48,
      empName: "N KIRAN KUMAR",
      userId: "necz9gga",
      employeeId: "MT6243",
      empMobNo: "9849697828",
      mail: "agmgnt.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 49,
      empName: "V LINGAIAH",
      userId: "jf7xvlha",
      employeeId: "MT6241",
      empMobNo: "9100111436",
      mail: "hydwest.kcp@gmail.com",
      reportingEmp: "I RAJA SEKHAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 50,
      empName: "P S V D PAVAN",
      userId: "dnp2f8gc",
      employeeId: "MT6239",
      empMobNo: "8008887984",
      mail: "khammam.kcp@gmail.com",
      reportingEmp: "G HEMANTHKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 51,
      empName: "A ANAND",
      userId: "qvj7uo9c",
      employeeId: "MT6237",
      empMobNo: "7338848845",
      mail: "villupuram.kcp@gmail.com",
      reportingEmp: "P SURESH BABU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 52,
      empName: "K SRI KRISHNA",
      userId: "33u672mp",
      employeeId: "MT6210",
      empMobNo: "9849697825",
      mail: "vijayawada.kcp@gmail.com",
      reportingEmp: "G HEMANTHKUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 53,
      empName: "SANAT KUMAR SAHU",
      userId: "gqdphm8h",
      employeeId: "MT6191",
      empMobNo: "9437484535",
      mail: "berhampur.kcp@gmail.com",
      reportingEmp: "J VIJAY KUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 54,
      empName: "CHAGANTI JAGADISH KUMAR",
      userId: "5c8h2xrn",
      employeeId: "MT6190",
      empMobNo: "9177404253",
      mail: "srikakulam.kcp@gmail.com",
      reportingEmp: "K HARANADH",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 55,
      empName: "KONDAKA ESWARA RAO",
      userId: "l5pu33hv",
      employeeId: "MT6189",
      empMobNo: "9100959788",
      mail: "vizag.kcp@gmail.com",
      reportingEmp: "J VIJAY KUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 56,
      empName: "CHINTAMANI RAUL",
      userId: "due1fnht",
      employeeId: "MT6184",
      empMobNo: "9437164535",
      mail: "berhampur.kcp@gmail.com",
      reportingEmp: "J VIJAY KUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 57,
      empName: "V MADHU BABU",
      userId: "shfkhl8u",
      employeeId: "MT6183",
      empMobNo: "9100111748",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "ARUN KUMAR G",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 58,
      empName: "NAZEER SHEIK",
      userId: "xebbgff2",
      employeeId: "MT6181",
      empMobNo: "9849697832",
      mail: "vijayawada.kcp@gmail.com",
      reportingEmp: "E BANGARU MOULI",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 59,
      empName: "S GANESAN",
      userId: "cwbf640i",
      employeeId: "MT6176",
      empMobNo: "8754582286",
      mail: "katpadi.kcp@gmail.com",
      reportingEmp: "AZEEM HAFEEZ M",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 60,
      empName: "K HARISH KUMAR",
      userId: "ubq5tpu6",
      employeeId: "MT6171",
      empMobNo: "7330612225",
      mail: "westgodavari.kcp@gmail.com",
      reportingEmp: "B MAHENDRA RAJU",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 61,
      empName: "M SRINIVAS",
      userId: "ies19b7y",
      employeeId: "MT6147",
      empMobNo: "9177335560",
      mail: "nellore.kcp@gmail.com",
      reportingEmp: "BALA VENKATESWARA RAO S",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 62,
      empName: "A M V PRASAD",
      userId: "wknrcgpr",
      employeeId: "MT6063",
      empMobNo: "9177404254",
      mail: "vizag.kcp@gmail.com",
      reportingEmp: "J VIJAY KUMAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 63,
      empName: "J VIJAY KUMAR",
      userId: "4k09lme9",
      employeeId: "MT6061",
      empMobNo: "9177707415",
      mail: "vizag.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 64,
      empName: "P JAY KUMAR",
      userId: "2gkamkuz",
      employeeId: "MT6060",
      empMobNo: "9177707414",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 65,
      empName: "G ARUN KUMAR",
      userId: "6rq4i53o",
      employeeId: "MT6049",
      empMobNo: "8978100228",
      mail: "hyderabad.kcp@gmail.com",
      reportingEmp: "Radhakrishna Murthy G",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 66,
      empName: "Y V VIJAYA KUMAR",
      userId: "5ry2pqh5",
      employeeId: "MT6047",
      empMobNo: "9177707417",
      mail: "vizianagaram.kcp@gmail.com",
      reportingEmp: "K HARANADH",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 67,
      empName: "M PRABAKAR",
      userId: "jahf19yi",
      employeeId: "MT6037",
      empMobNo: "9500052547",
      mail: "katpadi.kcp@gmail.com",
      reportingEmp: "MANIVEL KRISHNAN",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 68,
      empName: "B MAHENDRA RAJU",
      userId: "0au239ar",
      employeeId: "MT6021",
      empMobNo: "9849697823",
      mail: "westgodavari.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 69,
      empName: "M PRAVEEN BABU",
      userId: "doy50az1",
      employeeId: "MT6012",
      empMobNo: "8008887841",
      mail: "nalgonda.kcp@gmail.com",
      reportingEmp: "I RAJA SEKHAR",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 70,
      empName: "G HEMANTH KUMAR",
      userId: "1vtx0epd",
      employeeId: "MT5002",
      empMobNo: "9849697824",
      mail: "vijayawada@kcp.co.in",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 71,
      empName: "I RAJA SEKHAR",
      userId: "misx2ckn",
      employeeId: "MT0006",
      empMobNo: "9849697835",
      mail: "hydwest.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 72,
      empName: "A SELVAKUMAR",
      userId: "h2gw8yoc",
      employeeId: "M97038",
      empMobNo: "9500049608",
      mail: "chennai@kcp.co.in",
      reportingEmp: "NARAYANAN S",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 73,
      empName: "K RAMAKRISHNA",
      userId: "cyhglacf",
      employeeId: "M91005",
      empMobNo: "9849697821",
      mail: "ramakrishna.k@kcp.co.in",
      reportingEmp: "KCP RO",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 74,
      empName: "K HARANADH",
      userId: "xuotrhvi",
      employeeId: "M20232",
      empMobNo: "9177404221",
      mail: "srikakulam.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 75,
      empName: "S BALA VENKATESWARA RAO",
      userId: "fhndvbxg",
      employeeId: "M20231",
      empMobNo: "9849697833",
      mail: "nellore.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 76,
      empName: "N V S N RAJU",
      userId: "yooimm6i",
      employeeId: "M20203",
      empMobNo: "9849697822",
      mail: "eastgodavari.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
    {
      id: 77,
      empName: "E BANGARU MOULI",
      userId: "jny9t1u9",
      employeeId: "M20202",
      empMobNo: "9849550858",
      mail: "nalgonda.kcp@gmail.com",
      reportingEmp: "K RAMAKRISHNA",
      empTeam: "Default",
      empDesig: "None",
      joiningDate: "-",
    },
  ],
};
