import {
  DataGrid,
  // GridToolbarContainer,
  // GridToolbarExport,
  // useGridApiContext,
  // gridFilteredSortedRowIdsSelector,
  // gridVisibleColumnFieldsSelector,
} from "@mui/x-data-grid";

export const TableGrid = ({
  tableRow,
  tableColumn,
  rowHeight,
  initialPageSize,
}) => {
  // function CustomToolbar(props) {
  //   return (
  //     <GridToolbarContainer {...props}>
  //       {/* <GridToolbarExport /> */}
  //       <ExportButton />
  //     </GridToolbarContainer>
  //   );
  // }

  // const ExportButton = (props) => {
  //   const apiRef = useGridApiContext();
  //   return (
  //     <button
  //       onClick={() => {
  //         handleExport(apiRef);
  //       }}
  //     >
  //       Download Excel
  //     </button>
  //   );
  // };

  // function handleExport(apiRef) {
  //   const data = getExcelData(apiRef);

  //   const rows = data.map((row) => {
  //     const mRow = {};
  //     for (const key of row.keys) {
  //       mRow[key] = row[key];
  //     }
  //     return mRow;
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(rows);
  //   XLSX.utils.sheet_add_aoa(worksheet, [[...rows.columnNames]], {
  //     origin: "A1",
  //   });

  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, rows.sheetName);
  //   XLSX.writeFile(workbook, rows.fileName, { compression: true });
  // }

  // function getExcelData(apiRef) {
  //   // Select rows and columns
  //   const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  //   const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  //   // Format the data. Here we only keep the value
  //   const data = filteredSortedRowIds.map((id) => {
  //     const row = {};
  //     visibleColumnsField.forEach((field) => {
  //       row[field] = apiRef.current.getCellParams(id, field).value;
  //     });
  //     return row;
  //   });

  //   return data;
  // }

  return (
    <>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              backgroundColor: "#eee",
              fontSize: "15px",
            },

          // border: 1,
          // borderColor: 'blue',
        }}
        rows={tableRow}
        getRowHeight={() => rowHeight}
        columns={tableColumn}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: initialPageSize,
            },
          },
        }}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
        columnHeaderHeight={40}
        pageSizeOptions={[5, 10, 15, 20, 50]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </>
  );
};
